
import { defineComponent, reactive, ref } from "vue";
import { message } from "ant-design-vue";
import { viewFile, operationRecord, viewFileTrande } from "@/API/userCar";
import moment from "moment";
import { b64toBlob } from "@/utils/claim/b64toBlob";
import downloadFile from "@/utils/rv/downloadFile";
export default defineComponent({
  emits: ["update:visible", "close-view-visible"],
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true,
    },
    isViewData: {
      type: Object,
      default: () => {
        return {};
      },
      required: true,
    },
  },
  setup(props, { emit }) {
    const docName = ref("");
    const tableHeight = 600;
    const aUrl = ref<string>("");
    const columns = reactive<any>([
      { title: "操作角色", dataIndex: "operationRole", align: "center" },
      {
        title: "操作动作",
        dataIndex: "operationAction",
        align: "center",
        width: 100,
      },
      { title: "操作时间", dataIndex: "updatedAt", align: "center" },
    ]);
    const dataSource = reactive<any>([
      {
        role: "管理员",
        handleaction: "上传文件",
        time: "2021/09/11",
      },
    ]);
    const viewList = ref<any>([]);
    const title = ref<string>("二手车发票");
    const fileName = ref<string>(props.isViewData.vinNo);
    debugger;
    const fileType = ref<string>("");
    const initTitle = () => {
      if (props.isViewData.type == 1) {
        title.value = "二手车发票:FP_";
      } else if (props.isViewData.type == 2) {
        title.value = "登记证:DJZ_";
      } else if (props.isViewData.type == 3) {
        title.value = "身份证明:SFZM_";
      } else if (props.isViewData.type == 4) {
        title.value = "寄售合同:JSHT_";
      } else if (props.isViewData.type == 5) {
        title.value = "销售合同:XSHT_";
      } else if (props.isViewData.type == 6) {
        title.value = "认证报告:RZBG_";
      } else if (props.isViewData.type == 7) {
        title.value = "保修报告:BXBG_";
      } else {
        title.value = "其它:QT_";
      }

      // if (props.isViewData.type == 1) {
      //   title.value = "二手车发票:";
      // } else if (props.isViewData.type == 2) {
      //   title.value = "登记证:";
      // } else if (props.isViewData.type == 3) {
      //   title.value = "身份证明:";
      // } else if (props.isViewData.type == 4) {
      //   title.value = "寄售合同:";
      // } else if (props.isViewData.type == 5) {
      //   title.value = "销售合同:";
      // } else if (props.isViewData.type == 6) {
      //   title.value = "认证报告:";
      // } else if (props.isViewData.type == 7) {
      //   title.value = "保修报告:";
      // } else {
      //   title.value = "其它:";
      // }
    };
    // 初始化可查看页面 cpo+置换
    const init = () => {
      if (props.isViewData.types == 0) {
        // cpo销售的
        viewFile({ id: props.isViewData.id, type: props.isViewData.type }).then(
          (res: any) => {
            if (res.length) {
              res.forEach((ele: any) => {
                docName.value = ele.docName;
                fileType.value = ele.docType;
                const temp = {
                  imgUrl: "",
                  downUrl: "",
                  docType: true,
                };
                if (ele.base64) {
                  temp.imgUrl = URL.createObjectURL(
                    b64toBlob(ele.base64, "application/pdf")
                  );
                  temp.docType = false;
                } else {
                  temp.imgUrl = ele.downloadUrl;
                  temp.docType = true;
                }
                temp.downUrl = ele.downloadUrl;
                viewList.value.push(temp);
                return viewList.value;
              });
              aUrl.value = viewList.value[0].downUrl;
            } else {
              message.error("抱歉,未查询到可用文件信息~");
              emit("update:visible", false); //无数据关闭弹窗
            }
          }
        );
      } else {
        // 置换的查看
        viewFileTrande({
          id: props.isViewData.id,
          type: props.isViewData.type,
        }).then((res: any) => {
          if (res.length) {
            res.forEach((ele: any) => {
              fileType.value = ele.docType;
              const temp = {
                imgUrl: "",
                downUrl: "",
                docType: true,
              };
              if (ele.base64) {
                temp.imgUrl = URL.createObjectURL(
                  b64toBlob(ele.base64, "application/pdf")
                );
                temp.docType = false;
              } else {
                temp.imgUrl = ele.downloadUrl;
                temp.docType = true;
              }
              temp.downUrl = ele.downloadUrl;
              viewList.value.push(temp);
              return viewList.value;
            });
            aUrl.value = viewList.value[0].downUrl;
          } else {
            message.error("抱歉,未查询到可用文件信息~");
            emit("update:visible", false); //无数据关闭弹窗
          }
        });
      }
    };
    // 初始化操作记录信息 cpo+置换
    const initRecord = () => {
      // cpo销售+置换通用接口
      operationRecord({
        mainId: props.isViewData.id,
        type: props.isViewData.operationType,
        isDealer: 1,
      })
        .then((res) => {
          dataSource.length = 0;
          dataSource.push(...res);
        })
        .catch((err) => {
          console.log(err, "报错信息");
        });
    };
    const isTab = ref<number>(0);
    const changeTab = (param: number) => {
      console.log(param, "这是参数");
      isTab.value = param;
      if (param == 1) {
        initRecord();
      }
    };
    // 下载全部图片
    const downLoadAllImg = () => {
      const params = {
        url: viewList.value[0].downloadUrl,
        method: "get",
        fileName: `${moment(new Date()).format("YYYYMMDDhhmmss")}${{
          title,
        }}.jpg`,
      };
      downloadFile(params);
    };
    initTitle();
    init();
    return {
      viewList,
      title,
      close,
      downLoadAllImg,
      changeTab,
      isTab,
      dataSource,
      columns,
      tableHeight,
      aUrl,
      fileName,
      fileType,
      docName,
    };
  },
});
